import React, { useEffect, useState } from 'react';
import {
    Select,
    Input,
    FormControl,
    FormLabel,
} from '@chakra-ui/react';
import axios from 'axios';

const SearchSelection = ({ onSelect }) => {
    const serverUrl = process.env.REACT_APP_API_URL;
    const [options, setOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await axios.get(`${serverUrl}/api/clients-in-organization`);
                // console.log('fetchClients response data', JSON.stringify(response.data, null, 2));
                const clients = response.data.map(client => ({
                    value: client.id,
                    label: `${client.firstName} ${client.lastName} (${client.email})`
                }));
                setOptions(clients);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        fetchClients();
    }, []);

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <FormControl>
            {/* <FormLabel>Search and Select</FormLabel> */}
            <Input
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                size="sm"
            />
            <Select
                placeholder="Select an option"
                onChange={(e) => onSelect(e.target.value)}
                size="sm"
            >
                {filteredOptions.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Select>
        </FormControl>
    );
};

export default SearchSelection;
import React, { useEffect, useState } from 'react';
import { Box, Heading, Switch, HStack, Text, Spinner } from '@chakra-ui/react';
import GrayBox from '../../GrayBox';
import axios from 'axios';
import Cookies from 'js-cookie';
import { split } from 'sentence-splitter';

const Home = ({scrollRef}) => {
    const serverUrl = process.env.REACT_APP_API_URL;
    const [myFeed, setMyFeed] = useState([]);
    const [loading, setLoading] = useState(true);
    const [viewingTranscript, setViewingTranscript] = useState(false);

    useEffect(() => {
      setLoading(true);
      axios.get(`${serverUrl}/api/my-feed`, {
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      })
        .then(response => {
          console.log('My Feed:', response.data);
          return response.data;
        })
        .then(data => {
          console.log('Action Items:', data);
          const sortedData = data.sort((a, b) => {
            const dateA = new Date(a.metadata?.sentDateTime || a.receivedAt || a.startTime);
            const dateB = new Date(b.metadata?.sentDateTime || b.receivedAt || b.startTime);
            return dateB - dateA; // Descending order
          });
          console.log('Sorted Data:', sortedData);
          setMyFeed([...sortedData]);
          setLoading(false);

        });
    }, []);


  const handleShowArchived = (showArchived) => {
    console.log('Show Archived Correspondences:', showArchived);
    setLoading(true);
    axios.get(`${serverUrl}/api/my-feed?showArchived=${showArchived}`, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    })
      .then(response => {
        console.log('Archived Correspondences:', response.data);
        return response.data;
      })
      .then(data => {
        console.log('Archived Correspondences:', data);
        const sortedData = data.sort((a, b) => {
          const dateA = new Date(a.metadata?.sentDateTime || a.receivedAt || a.startTime);
          const dateB = new Date(b.metadata?.sentDateTime || b.receivedAt || b.startTime);
          return dateB - dateA; // Descending order
        });
        setMyFeed([...sortedData]);
        setLoading(false);
      });
  }

  useEffect(() => {
    const handleScrollPosition = () => {
      const savedPosition = sessionStorage.getItem('scrollPosition');
      if (savedPosition && scrollRef.current) {
        scrollRef.current.scrollTo(0, parseInt(savedPosition, 10));
        sessionStorage.removeItem('scrollPosition'); 
      }
    };
  
    if (!loading) {
      handleScrollPosition();
    }
  }, [loading, scrollRef]); // Include scrollRef in the dependency array

  const handleArchive = (itemId) => {
      console.log('Archive', itemId);
      // Update the state to remove the archived item
      let newFeed = myFeed.filter(item => item.id !== itemId);
      setMyFeed([...newFeed] );
  }

  // Function to split summary into sentences using sentence-splitter
  const getListItems = (summary) => {
    if (!summary) return [];

    const sentences = split(summary).filter(
      (element) => element.type === 'Sentence'
    );

    // Extract and trim each sentence
    return sentences.map((sentence) => sentence.raw.trim());
  };

  /**
 * Title Generation Logic for GrayBox Component
 *
 * The title prop for the GrayBox component is determined using the following logic:
 *
 * 1. **Use Existing Title if Defined**:
 *    - If `data.title` is defined, it is used directly as the title.
 *
 * 2. **Dynamic Title Generation**:
 *    - If `data.title` is not defined, the title is constructed based on the `correspondenceType`.
 *
 *    a. **For Email Correspondence** (`correspondenceType === 'email'`):
 *       - Concatenates the full names of all clients, separated by commas.
 *       - Includes the email subject if available.
 *       - Appends the sent date and time, formatted as 'MM/DD/YYYY, HH:MM AM/PM'.
 *
 *    b. **For Call Correspondence** (`correspondenceType === 'call'`):
 *       - Concatenates the full names of all clients, separated by commas.
 *       - Includes the event subject if available.
 *       - Appends the event start time if the event exists; otherwise, uses the call's startTime.
 *       - The date and time are formatted as 'MM/DD/YYYY, HH:MM AM/PM'.
 *
 *    c. **For Other Correspondence Types**:
 *       - Concatenates the full names of all clients, separated by commas.
 *       - Appends the start time of the correspondence, formatted as 'MM/DD/YYYY, HH:MM AM/PM'.
 *
 * **Example Outputs**:
 * - **With `data.title`**: "Project Kickoff Meeting"
 * - **Email**: "John Doe, Jane Smith - Important Update 10/01/2023, 10:00 AM"
 * - **Call**: "Alice Johnson: Weekly Sync on 10/02/2023, 11:00 AM"
 * - **Other**: "Bob Brown 10/03/2023, 2:00 PM"
 * **Implementation Details**:
 * - **Primary Condition (`data.title`)**:
 *   - Checks if `data.title` is defined.
 *   - If **yes**, it directly uses `data.title` as the title for the `GrayBox` component.
 *
 * - **Fallback Logic**:
 *   - If `data.title` is **not defined**, it proceeds with the existing logic:
 *     - **Email Correspondence**:
 *       - Constructs the title using client names, subject, and formatted sent date/time.
 *     - **Call Correspondence**:
 *       - Constructs the title using client names, event subject (if any), and formatted event start time or general start time.
 *     - **Other Correspondence Types**:
 *       - Constructs the title using client names and formatted start time.
 */
    return (
        <Box bg="white" p={8} overflowY="auto">
          <Heading color={'#00417D'} pb={4}>My Feed</Heading>
          <HStack justify="flex-end" mb={4}>
            <Text>View Archived Correspondences</Text>
            <Switch
              onChange={(e) => {
                const showArchived = e.target.checked;
                handleShowArchived(showArchived);
                // Logic to handle the switch state change
                console.log('Show Archived Correspondences:', showArchived);
                // You can add more logic here to filter correspondences based on the switch state
              }}
            />
          </HStack>
          {loading && <Spinner />}
          {!loading && myFeed.map((data, index) => (  
            <Box pr={20} key={data.id}>  
              <GrayBox
                key={data.id}  // Ensure key prop is unique
                imageSrc={data?.imageSrc}
                title={
                  data.title
                    ? data.title
                    : data.correspondenceType === "email"
                    ? `${data?.clients
                        .map((client) => `${client.firstName} ${client.lastName}`)
                        .join(", ")}${
                        data.metadata.subject ? ` - ${data.metadata.subject}` : ""
                      } ${new Date(
                        data?.metadata?.sentDateTime || data.receivedAt
                      ).toLocaleString("en-US", {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}`
                    : data.correspondenceType === "call"
                    ? `${data?.title ? data.title : `${data?.clients
                        .map((client) => `${client.firstName} ${client.lastName}`)
                        .join(", ")} ${
                        data?.event ? data.event.subject : ""
                      } ${
                        data?.event
                          ? new Date(data.event.startTime).toLocaleString("en-US", {
                              month: "numeric",
                              day: "numeric",
                              year: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            })
                          : new Date(data.startTime).toLocaleString("en-US", {
                              month: "numeric",
                              day: "numeric",
                              year: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            })
                      }`}`
                    : `${data?.clients
                        .map((client) => `${client.firstName} ${client.lastName}`)
                        .join(", ")} ${new Date(
                        data?.startTime
                      ).toLocaleString("en-US", {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}`
                }
                subtitle={data?.correspondenceType.charAt(0).toUpperCase() + data?.correspondenceType.slice(1)}
                listItems={
                  data?.correspondenceType === 'email'
                    ? getListItems(data?.metadata?.summary) || []
                    : data?.correspondenceType === 'call'
                    ? getListItems(data?.summary) || []
                    : [] // Default case if neither 'email' nor 'call'
                }
                rows={data?.ActionItem} 
                transcriptId={data?.id}
                client={data?.clients}
                type={data?.correspondenceType}
                id={data?.id}
                onArchive={() => handleArchive(data.id)} // Pass the archive handler
                scrollRef={scrollRef}
                errorItems={data?.ActionItem.filter(data => data.aiCompletionStatus === 'ERROR')} 
                correspondence={data}
                nonClientSpeakers={data?.nonClientSpeakers}
              />
            </Box>
          ))}
        {(myFeed.length === 0 && !loading) && (
            <Text>No items to display</Text>
        )}
        </Box>
      );
    };
    
    export default Home;
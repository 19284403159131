import React from 'react';
const Settings = () => {
  return (
    <div className="settings">
      <h1>Welcome to the Settings Page</h1>
      <div className="settings-options">
        <div className="option">
          <label htmlFor="highQualityTranscription">Toggle Higher Quality Transcription (will result inlonger processing time)</label>
          <input type="checkbox" id="highQualityTranscription" name="highQualityTranscription" />
        </div>
      </div>
    </div>
  );
};

export default Settings;
import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, VStack, HStack, Flex, IconButton, Progress, Button, Input, InputGroup, InputLeftElement, useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ArrowBackIcon, ChevronLeftIcon, ChevronRightIcon, RepeatIcon, SearchIcon } from '@chakra-ui/icons';
import { FaPlay, FaPause, FaEdit, FaSave, FaTimes } from 'react-icons/fa';

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const Correspondence = () => {
  const { id } = useParams();
  const [callData, setCallData] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [groupedTranscription, setGroupedTranscription] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const serverUrl = process.env.REACT_APP_API_URL;
  const audioRef = React.useRef(null);
  const toast = useToast();

  useEffect(() => {
    const fetchTranscript = async () => {
      try {
        const response = await axios.get(serverUrl + `/transcripts/${id}`, { headers: { Authorization: `Bearer ${Cookies.get('jwtToken')}` }, withCredentials: true });
        console.log("Received Response: ", response.data);
        setCallData(response.data);
        setNewTitle(response.data.call.title); // Initialize newTitle with current title
        const grouped = groupTranscription(response.data.call.transcription);
        setGroupedTranscription(grouped);
      } catch (error) {
        console.error('Error fetching transcript:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch transcript.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };
    fetchTranscript();
  }, [id, serverUrl, toast]);

  const groupTranscription = (transcription) => {
    const grouped = [];
    let currentGroup = null;
    transcription.forEach(entry => {
      if (currentGroup && currentGroup.speaker_name === entry.speaker_name) {
        currentGroup.entries.push(entry);
      } else {
        if (currentGroup) grouped.push(currentGroup);
        currentGroup = { speaker_name: entry.speaker_name, entries: [entry] };
      }
    });
    if (currentGroup) grouped.push(currentGroup);
    return grouped;
  };

  const handlePlayPause = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    const currentTime = audioRef.current.currentTime;
    setCurrentTime(currentTime);
    const progress = (currentTime / audioRef.current.duration) * 100;
    setProgress(progress);
  };

  const handleSkip = (seconds) => {
    audioRef.current.currentTime += seconds;
  };

  const jumpToTimestamp = (timestamp) => {
    audioRef.current.currentTime = timestamp;
    if (!isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const filteredTranscription = groupedTranscription.filter(group =>
    group.entries.some(entry =>
      entry.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
      entry.speaker_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  if (!callData) {
    return <Box>Loading...</Box>;
  }

  const handleSeekClick = (e) => {
    const progressBar = e.target;
    const rect = progressBar.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const seekTime = (clickX / progressBar.clientWidth) * audioRef.current.duration;
    audioRef.current.currentTime = seekTime;
    setProgress((seekTime / audioRef.current.duration) * 100);
  };

  const getHighlightedText = (entry) => {
    const isHighlighted =
      currentTime >= entry.start_timestamp &&
      currentTime <= entry.end_timestamp;
    return (
      <Text
        as="span"
        key={entry.start_timestamp}
        bg={isHighlighted ? 'lightblue' : 'transparent'}
      >
        {entry.text + ' '}
      </Text>
    );
  };

  // Event Handlers for Title Editing
  const handleEditTitle = () => {
    setIsEditingTitle(true);
  };

  const handleCancelEditTitle = () => {
    setIsEditingTitle(false);
    setNewTitle(callData.call.title);
  };

  const handleSaveTitle = async () => {
    if (newTitle.trim() === '') {
      toast({
        title: 'Validation Error',
        description: 'Title cannot be empty.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.put(
        `${serverUrl}/update-title/${callData.call.id}`,
        { title: newTitle },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtToken')}`,
          },
        }
      );
      setCallData((prev) => ({
        ...prev,
        call: {
          ...prev.call,
          title: response.data.call.title,
        },
      }));
      setIsEditingTitle(false);
      toast({
        title: 'Title Updated',
        description: 'The call title has been successfully updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating title:', error);
      toast({
        title: 'Update Failed',
        description: 'There was an error updating the title.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box bg="white" h="100%" p={8} pb={24} overflowY="auto">
      <IconButton
        aria-label="Go back"
        icon={<ArrowBackIcon />}
        onClick={() => window.history.back()}
        mb={4}
      />
      <VStack align="stretch" spacing={4}>
        {/* Editable Title Section */}
        <Box position="relative" role="group">
          {isEditingTitle ? (
            <VStack align="start" spacing={2}>
              <Input
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                placeholder="Enter new title"
                size="lg"
              />
              <HStack spacing={2}>
                <Button size="sm" colorScheme="green" onClick={handleSaveTitle}>Save</Button>
                <Button size="sm" colorScheme="red" onClick={handleCancelEditTitle}>Cancel</Button>
              </HStack>
            </VStack>
          ) : (
            <HStack align="center" spacing={2}>
              <Heading color="#00417D" fontSize="32px">
                {callData.call.title}
              </Heading>
              <IconButton
                aria-label="Edit Title"
                icon={<FaEdit />}
                size="sm"
                onClick={handleEditTitle}
                variant="ghost"
                opacity="0"
                transition="opacity 0.2s"
                _groupHover={{ opacity: 1 }}
              />
            </HStack>
          )}
        </Box>

        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Search transcript"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>

        <Box>
          {filteredTranscription.map((group, index) => (
            <Flex key={index} mt={2} align="start">
              <Box>
                <Text as="span" fontWeight="bold">{group.speaker_name}: </Text>
                <Text as="span">
                  {group.entries.map(entry => getHighlightedText(entry))}
                </Text>
              </Box>
              <Button 
                size="xs" 
                ml={2} 
                mt={1}
                variant="link"
                onClick={() => jumpToTimestamp(group.entries[0].start_timestamp)}
              >
                {formatTime(group.entries[0].start_timestamp)}
              </Button>
            </Flex>
          ))}
        </Box>
      </VStack>

      {/* Audio Player */}
      <Box
        position="fixed"
        bottom={0}
        left={262}  
        right={8}
        bg="gray.800"
        color="white"
        p={4}
        zIndex={1000}
      >
        <Flex align="center" justify="space-between">
          <IconButton
            icon={isPlaying ? <FaPause /> : <FaPlay />}
            onClick={handlePlayPause}
            aria-label={isPlaying ? "Pause" : "Play"}
            colorScheme="blue"
            size="lg"
          />
          <HStack spacing={4} flex={1} mx={4}>
            <IconButton
              icon={<ChevronLeftIcon />}
              onClick={() => handleSkip(-10)}
              aria-label="Rewind 10 seconds"
            />
            <Progress
              value={progress}
              flex={1}
              size="sm"
              colorScheme="blue"
              onClick={handleSeekClick}
              sx={{ cursor: 'pointer' }}  // Cursor changes to pointer on hover
            />
            <IconButton
              icon={<ChevronRightIcon />}
              onClick={() => handleSkip(10)}
              aria-label="Forward 10 seconds"
            />
          </HStack>
          <IconButton
            icon={<RepeatIcon />}
            onClick={() => {
              audioRef.current.currentTime = 0;
              audioRef.current.play();
            }}
            aria-label="Restart"
          />
        </Flex>
        <audio
          ref={audioRef}
          src={callData.audioUrl}
          onTimeUpdate={handleTimeUpdate}
          onEnded={() => setIsPlaying(false)}
          style={{ display: 'none' }}
        />
      </Box>
    </Box>
  );
};

export default Correspondence;
import React, { useEffect, useState } from 'react';
import {
  Box, Collapse, Heading, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Checkbox,
  VStack,
  List,
  Icon,
  ListItem,
  HStack,
  Button,
  useToast,
  Switch,
  Input,
  InputGroup,
  InputLeftElement,
  Image,
  Grid
} from '@chakra-ui/react';
import BlueBox from '../../BlueBox';
import { ChevronDownIcon, ChevronUpIcon, CheckIcon, CloseIcon, CheckCircleIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { IoMdHappy, IoMdSad } from 'react-icons/io';
import { FaRegUser, FaSearch } from 'react-icons/fa';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
const Meetings = () => {
  const serverUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate(); 
  const [events, setEvents] = useState([]);
  const [webConferenceUrl, setWebConferenceUrl] = useState('');
  const [botId, setBotId] = useState(null); 
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Automatically get the user's time zone

  const toast = useToast();

  useEffect(() => {
    const fetchEvents = async () => {
      console.log('Fetching events...');
      console.log(`response endpoint:${serverUrl}/api/events`);
      try {
        const response = await axios.get(`${serverUrl}/api/events`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}` // Include the token in the request headers
          }
        });
        console.log('Fetched Meetings:', response.data); // Log the fetched meetings
        setEvents(response.data); 
      } catch (error) {
        console.error('Error fetching meetings:', error);
      }
    };
    
    fetchEvents();
  }, []); 

  const formatDate = (dateString, timeZone) => {
    const localDate = new Date(dateString); // Create Date object from the ISO string
    const options = { 
        month: 'long', 
        day: 'numeric', 
        hour: 'numeric', 
        minute: 'numeric', 
        hour12: true,
        timeZone: timeZone // Use the provided time zone
    };
    return localDate.toLocaleString('en-US', options); // Format according to the user's time zone
  };

  const handleLiveCall = async () => {
    if (webConferenceUrl) {
      try {
        const response = await axios.post(`${serverUrl}/api/createBot`, 
          { meetingUrl: webConferenceUrl }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            },
          }
        );
        console.log('handleLiveCall response data:', response.data);
        const createdBotId = response.data.botId; // Assuming the response contains the botId
        setBotId(createdBotId); // Store the botId in state
        console.log('Created bot with ID:', createdBotId);
        toast({ 
          title: 'DataDasher Notetaker',
          description: 'The DataDasher Notetaker will join your meeting in a few minutes.',
          status: 'info',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error creating bot:', error.response ? error.response.data : error.message);
      }
    } else {
      console.error('Please enter a valid web conference URL.');
    }
  };
  
  const filteredEvents = events
    .filter(event => (event.location || event.webConfLink) && new Date(event.startTime) > new Date()); // Filter events based on the condition that they have a non-null location or a non-null web conference link, and are a future event

  return (
    <Box bg="white" p={8}>
      <Heading color={'#00417D'} fontSize={'32px'}>Meetings</Heading>
      <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={8}>
        <ActionBox image={<Image src="/images/Outlook.png"/>} description="Connect your Outlook calendar so that DataDasher will automatically join your scheduled meetings. " description2={<Text mt={1}>DataDasher supports Zoom, Google Meet, Webex, and Microsoft Teams.</Text>} buttonText="Integrations" buttonAction={() => navigate('/integrations')} />
        {/* <ActionBox image={<Image src="/images/DD_logo.png"/>} description="Invite dev@datadasher.ai to join your scheduled meetings." description2={<Input w={'200px'} mt={4} placeholder="dev@datadasher.ai" bg={'white'} _placeholder={{ color: 'black' }} h={'50px'} />} buttonText="Copy" buttonAction={() => { }} /> */}
        <ActionBox image={<Image src="/images/DD_logo.png"/>} description="Record in person meetings live using our mobile app." description2={<Text mt={4}>These meetings are automatically synced to the desktop app.</Text>} buttonText="Download" buttonAction={() => { }} />
        <ActionBox 
          image={<Image src="/images/DD_logo.png"/>} 
          description="Enter your web conference link to have DataDasher join your call." 
          description2={
            <Input 
              value={webConferenceUrl} 
              onChange={(e) => setWebConferenceUrl(e.target.value)} 
              placeholder="Enter web conference link" 
              mt={2} 
            />
          } 
          buttonText="Join Live Call" 
          buttonAction={handleLiveCall} 
        />
      </Grid>
      <Heading color={'black'} fontSize={'24px'} mt={8}>Upcoming Meetings</Heading>
      <Text color={'gray'} fontSize={'16px'} mt={4}>Events from your Outlook Calendar for the next 30 days will be displayed here.</Text>
      <VStack alignItems={'start'} mt={4}>
        <HStack justifyContent="space-between" w="90%">  
          <Text>Meeting Title</Text>
          <Text>Meeting Date and Location</Text>
          <Text>DataDasher Join Status</Text>
        </HStack>
        {filteredEvents.length === 0 ? ( // Use filtered events to check if there are no events
          <Text>No events found.</Text>
        ) : (
          filteredEvents
          .sort((a, b) => new Date(a.startTime) - new Date(b.startTime)) // Sort events by startTime, recent first
          .map((event) => {
            const unformattedStartTime = event.startTime; // Unformatted start time
            const unformattedEndTime = event.endTime; // Unformatted end time
            const formattedStartTime = formatDate(unformattedStartTime, userTimeZone); // Pass the user's time zone
            const formattedEndTime = formatDate(unformattedEndTime, userTimeZone); // Pass the user's time zone
            return (
              <EventBox 
                key={event.id}
                eventId={event.id} 
                subject={event.subject} 
                startTime={formattedStartTime} 
                endTime={formattedEndTime} 
                location={event.webConfLink || event.location} 
                audioBotJoinStatus={event.audioBotJoinStatus ?? true}
                botId={event.botId ?? null}
              />
            );
          })
        )}
      </VStack>
    </Box>
  );
};

const ActionBox = ({ image, description, description2, buttonText, buttonAction }) => {
  return (
    <Box bg="#F5F5F5" pt={8} pl={4} pr={4} w="100%" h={'350px'} position={'relative'} borderRadius={'8px'}>
      <VStack textAlign={'center'}>
        {image}
        <Text>{description}</Text>
        {description2}
        <Button onClick={buttonAction} bg={'#A3BBD0'} w={'150px'} h={'50px'} position={'absolute'} bottom={'30px'}>
          <Text fontWeight={400}>{buttonText}</Text>
        </Button>
      </VStack>
    </Box>
  );
};

const EventBox = ({ eventId, subject, startTime, endTime, location, audioBotJoinStatus, botId }) => {
  const serverUrl = process.env.REACT_APP_API_URL;
  const [isChecked, setIsChecked] = useState(audioBotJoinStatus);

  const handleToggle = async () => {
    const newAttendingStatus = !isChecked;
    setIsChecked(newAttendingStatus);

    try {
      await axios.patch(`${serverUrl}/api/events/${eventId}`, {
        botId: botId, // Update the botId field
        audioBotJoinStatus: newAttendingStatus // Update the audioBotJoinStatus field
      });
      console.log('Attendance status updated:', newAttendingStatus);
    } catch (error) {
      console.error('Error updating attendance status:', error);
    }
  };
  return (
    <Box bg="#F5F5F5" p={4} position={'relative'} borderRadius={'8px'} w={'90%'}>
      <HStack textAlign={'center'} justifyContent={'space-between'}>
        <Text fontWeight="bold">{subject}</Text>
        <VStack>
          <Text>{startTime} - {endTime}</Text>
          <Text>{location}</Text>
        </VStack>
        <Switch isChecked={isChecked} onChange={handleToggle} />
      </HStack>
    </Box>
  );
};
export default Meetings;
import React, { useState, useEffect } from 'react';
import { Box, Collapse, IconButton, Text, Textarea, useDisclosure, Image, useToast, HStack, VStack, Button, Input, OrderedList, ListItem } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { FaUser, FaSave, FaEdit, FaInfo } from 'react-icons/fa';
import BlueBox from './BlueBox';
import axios from 'axios';
import SearchSelection from './SearchSelection';
import Cookies from 'js-cookie';
import { split } from 'sentence-splitter';

const GrayBox = ({ imageSrc, title, subtitle, listItems, rows, transcriptId, client, id, type, scrollRef , onArchive, errorItems, correspondence, nonClientSpeakers }) => {
  // console.log('Image Src:', imageSrc);
  const toast = useToast();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [isEditingSummary, setIsEditingSummary] = useState(false);
  const [newSummary, setNewSummary] = useState(listItems.join(' '));
  const [stateTitle, setStateTitle] = useState(title);
  const [stateSubtitle, setStateSubtitle] = useState(subtitle);
  const [stateItems, setStateItems] = useState(listItems);
  const [stateRows, setStateRows] = useState(rows);
  const [stateTranscriptId, setStateTranscriptId] = useState(transcriptId);
  const [stateClient, setStateClient] = useState(client);
  const [stateId, setStateId] = useState(id);
  const [stateType, setStateType] = useState(type);
  const [stateCorrespondence, setStateCorrespondence] = useState(correspondence);
  const [showErrorTooltip, setShowErrorTooltip] = useState(false);
  const [speakersLoading, setSpeakersLoading] = useState(false);
  const [stateImageSrc, setStateImageSrc] = useState(imageSrc);
  const [speakerSelections, setSpeakerSelections] = useState({});
  const [updatingSpeakers, setUpdatingSpeakers] = useState(false);
  const [isHoveredTitle, setIsHoveredTitle] = useState(false);
  const [isHoveredSummary, setIsHoveredSummary] = useState(false);
  const serverUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setStateImageSrc(imageSrc);
    setStateTitle(title);
    setStateItems(listItems.map(item => item.split('\n')));
    setStateRows(rows);
    setStateCorrespondence(correspondence);
  }, [imageSrc, title, subtitle, listItems, rows, correspondence]);

  useEffect(() => {
    setStateTranscriptId(transcriptId);
    setStateClient(client);
    setStateId(id);
    setStateType(type);
  }, [transcriptId, client, id, type]);

  const handleEditTitle = () => {
    setIsEditingTitle(true);
  };

  const handleCancelEditTitle = () => {
    setIsEditingTitle(false);
    setNewTitle(stateTitle);
  };

  const handleSaveTitle = async () => {
    if (newTitle.trim() === '') {
      toast({
        title: "Validation Error",
        description: "Title cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.put(
        `${serverUrl}/update-title/${stateId}`,
        { title: newTitle },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtToken")}`,
          },
        }
      );
      setStateTitle(response.data.call.title);
      setIsEditingTitle(false);
      toast({
        title: "Title Updated",
        description: "The call title has been successfully updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating title:", error);
      toast({
        title: "Update Failed",
        description: "There was an error updating the title.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditSummary = () => {
    setIsEditingSummary(true);
  };

  /**
   * Cancels the summary editing and reverts to the original summary.
   */
  const handleCancelEditSummary = () => {
    setIsEditingSummary(false);
    setNewSummary(stateItems.join(' '));
  };

  /**
   * Handles saving the edited summary.
   */
  const handleSaveSummary = async () => {
    if (newSummary.trim() === '') {
      toast({
        title: "Validation Error",
        description: "Summary cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const formattedSummary = newSummary.trim();

      const response = await axios.put(
        `${serverUrl}/update-summary/${stateId}`,
        { summary: formattedSummary },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtToken")}`,
          },
        }
      );

      // Split the summary into sentences for display
      const sentences = split(formattedSummary)
        .filter(element => element.type === 'Sentence')
        .map(sentence => sentence.raw.trim());

      setStateItems(sentences);
      setIsEditingSummary(false);
      toast({
        title: "Summary Updated",
        description: "The summary has been successfully updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating summary:", error);
      toast({
        title: "Update Failed",
        description: "There was an error updating the summary.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  /**
   * Handles input changes for the summary.
   * @param {string} value - The new summary value.
   */
  const handleSummaryChange = (e) => {
    setNewSummary(e.target.value);
  };

  const handleMouseEnterTitle = () => {
    setIsHoveredTitle(true);
  };

  const handleMouseLeaveTitle = () => {
    setIsHoveredTitle(false);
  };

  const handleMouseEnterSummary = () => {
    setIsHoveredSummary(true);
  };

  const handleMouseLeaveSummary = () => {
    setIsHoveredSummary(false);
  };

  const handleMouseEnter = () => {
    setShowErrorTooltip(true);
    errorItems.forEach(item => console.log(item));
  };

  const handleMouseLeave = () => {
    setShowErrorTooltip(false);
  };

  const handleResync = async () => {
    setSpeakersLoading(true);
    console.log('GrayBox Component: handleResync - Initiating resync process');
    try {
        console.log('GrayBox Component: handleResync - Resyncing all clients for the organization');
        const response = await axios.post(`${serverUrl}/api/clients/resync-all`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            }
        });

        if (response.data.message) {
            toast({
                title: "Resync Initiated",
                description: "All client data resync has been initiated. This may take a few moments.",
                status: "info",
                duration: 5000,
                isClosable: true,
            });
        } else {
            throw new Error(response.data.message || "Failed to initiate resync");
        }
    } catch (error) {
        console.error("Error initiating resync:", error);
        toast({
            title: "Resync Failed",
            description: `Failed to initiate resync: ${error.message}`,
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    } finally {
        setSpeakersLoading(false); // Reset loading state after completion
        console.log('GrayBox Component: handleResync - Resync process completed');
    }
  };

  // Handler for selecting a client for a speaker
  const handleClientSelect = (speaker, selectedClientId) => {
    console.log(`GrayBox Component: handleClientSelect - Speaker: ${speaker}, Selected Client ID: ${selectedClientId}`);
    setSpeakerSelections(prev => ({
      ...prev,
      [speaker]: selectedClientId
    }));
  };

  // Handler for "Done" button click
  const handleDone = async (speaker) => {
    console.log(`GrayBox Component: handleDone - Processing speaker: ${speaker}`);
    const selectedClientId = speakerSelections[speaker];
    if (!selectedClientId) {
      console.warn(`GrayBox Component: handleDone - No client selected for speaker: ${speaker}`);
      toast({
        title: "Client Not Selected",
        description: "Please select a client before proceeding.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    console.log(`GrayBox Component: handleDone - Selected Client ID for speaker ${speaker}: ${selectedClientId}`);
    setUpdatingSpeakers(true);
    try {
      console.log(`GrayBox Component: handleDone - Sending request to server to assign speaker: ${speaker}`);
      const response = await axios.post(`${serverUrl}/api/calls/${id}/assign-speaker`, {
        speaker,
        clientId: selectedClientId
      }, {
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });
      console.log(`GrayBox Component: handleDone - Server response:`, response.data);

      // Update local nonClientSpeakers by removing the processed speaker
      const updatedNonClientSpeakers = nonClientSpeakers.filter(s => s !== speaker);
      setStateCorrespondence(prev => ({
        ...prev,
        nonClientSpeakers: updatedNonClientSpeakers
      }));
      console.log(`GrayBox Component: handleDone - Removed speaker ${speaker} from nonClientSpeakers`);

      toast({
        title: "Speaker Assigned",
        description: `${speaker} has been assigned to the selected client.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(`GrayBox Component: handleDone - Error assigning speaker ${speaker}:`, error);
      toast({
        title: "Error",
        description: `Failed to assign speaker: ${error.response?.data?.error || error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setUpdatingSpeakers(false);
      console.log(`GrayBox Component: handleDone - Completed processing for speaker: ${speaker}`);
    }
  };

  return (
    <Box bg="#F5F5F5" p={4} mt={4} borderRadius={20} position="relative">
      {/* Header Section */}
      <HStack align="start">
        {/* Icon Button in its own vertical stack */}
        <VStack spacing={0}>
          <IconButton
            aria-label="User"
            icon={<FaUser />}
            variant="ghost"
            size="lg"
          />
        </VStack>

        {/* Title, Subtitle, and Summary Section */}
        <VStack align="start" mb={1} flex="1">
          {/* Editable Title Section */}
          <HStack
            align="center"
            width="100%"
            onMouseEnter={handleMouseEnterTitle}
            onMouseLeave={handleMouseLeaveTitle}
          >
            {isEditingTitle ? (
              <VStack align="start" spacing={1} width="100%">
                <Input
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  placeholder="Enter new title"
                />
                <HStack>
                  <Button size="sm" colorScheme="green" onClick={handleSaveTitle}>
                    Save
                  </Button>
                  <Button size="sm" colorScheme="red" onClick={handleCancelEditTitle}>
                    Cancel
                  </Button>
                </HStack>
              </VStack>
            ) : (
              <>
                <Text fontSize="md" fontWeight="bold">
                  {stateTitle}
                </Text>
                {isOpen && type === 'call' && (
                  <IconButton
                    aria-label="Edit Title"
                    icon={<FaEdit />}
                    size="sm"
                    onClick={handleEditTitle}
                    variant="ghost"
                    style={{
                      opacity: isHoveredTitle ? 1 : 0,
                      transition: 'opacity 0.3s ease-in-out'
                    }}
                  />
                )}
              </>
            )}
          </HStack>

          {/* Subtitle Section */}
          <Text color="gray.800" mb={0}>
            {stateSubtitle}
          </Text>

          {/* Summary Section */}
          <Box
            width="100%"
            onMouseEnter={handleMouseEnterSummary}
            onMouseLeave={handleMouseLeaveSummary}
          >
            {/* Always show the summary bullet points */}
            {!isEditingSummary ? (
              <OrderedList spacing={4} pl={4}>
                {stateItems.map((item, index) => (
                  <ListItem key={index}>
                    <Text whiteSpace="pre-wrap">{item}</Text>
                  </ListItem>
                ))}
              </OrderedList>
            ) : (
              isOpen && ( // Only show the editable textarea when chevron is expanded
                <Box width="100%">
                  <Textarea
                    value={newSummary}
                    onChange={handleSummaryChange}
                    placeholder="Enter new summary"
                    size="sm"
                  />
                  <HStack mt={2}>
                    <Button size="xs" colorScheme="green" onClick={handleSaveSummary}>
                      Save
                    </Button>
                    <Button size="xs" colorScheme="red" onClick={handleCancelEditSummary}>
                      Cancel
                    </Button>
                  </HStack>
                </Box>
              )
            )}
            {isOpen && (type === 'call' || type === 'email') && !isEditingSummary && (
              <Button
                size="sm"
                mt={2}
                onClick={handleEditSummary}
                leftIcon={<FaEdit />}
                variant="outline"
                style={{
                  opacity: isHoveredSummary ? 1 : 0,
                  transition: 'opacity 0.3s ease-in-out'
                }}
              >
                Edit Summary
              </Button>
            )}
          </Box>
        </VStack>
      </HStack>

      {/* Error Tooltip and Expand/Collapse Buttons */}
      <HStack position="absolute" top={2} right={2} spacing={2}>
        {errorItems.length > 0 && (
          <Box position="relative">
            <IconButton
              aria-label="Error Info"
              icon={<FaInfo color="red" />}
              variant="ghost"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              top={2}
              right={12}
            />
            {showErrorTooltip && (
              <Box
                position="absolute"
                bg="gray.200"
                p={2}
                borderRadius="md"
                boxShadow="md"
                zIndex={1}
                top="100%"
                left="-450px"
                width="500px"
              >
                {errorItems.map((data, index) => (
                  <Box key={index} mt={5}>
                    <Text color="red.500">Action: {data.description}</Text>
                    <Text color="red.500">Error: {data.aiCompletionMessage}</Text>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}
        <IconButton
          aria-label="Expand"
          icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          onClick={onToggle}
          variant="ghost"
          position="absolute"
          top={2}
          right={2}
        />
      </HStack>

      {/* Collapsible Content */}
      <Collapse in={isOpen} animateOpacity>
        <BlueBox
          actions={stateRows}
          transcriptId={stateTranscriptId}
          client={stateClient}
          id={stateId}
          type={stateType}
          onArchive={(itemId) => {
            onArchive(itemId);
            onClose();
          }}
          scrollRef={scrollRef}
          correspondence={stateCorrespondence}
        />
        {stateCorrespondence.correspondenceType === 'call' &&
          nonClientSpeakers &&
          nonClientSpeakers.length > 0 && (
            <Box bg="#00417D33" p={4} borderRadius="8px" mt={5}>
              <Text>
                On this call, we detected speaker(s) with names different from the names in your CRM.
                <br />
                Add them to your CRM first, click Resync CRM, then select their name, and click Done.
              </Text>
              {nonClientSpeakers.map((speaker, i) => (
                <HStack key={i} mb={2} mt={4} alignItems="center" w="full" px={4}>
                  <HStack spacing={2} w="30%">
                    <Text>Retag {speaker} as</Text>
                  </HStack>
                  <HStack spacing={2} w="50%">
                    <SearchSelection
                      onSelect={(selectedClient) => handleClientSelect(speaker, selectedClient)}
                    />
                  </HStack>
                  <HStack spacing={2} w="25%" justify="flex-end">
                    <Button
                      size="sm"
                      onClick={handleResync}
                      isLoading={updatingSpeakers}
                      loadingText="Resyncing"
                      px={4}
                    >
                      Resync CRM
                    </Button>
                    <Button
                      size="sm"
                      onClick={() => handleDone(speaker)}
                      isLoading={updatingSpeakers}
                      loadingText="Processing"
                      px={4}
                    >
                      Done
                    </Button>
                  </HStack>
                </HStack>
              ))}
            </Box>
          )}
      </Collapse>
    </Box>
  );
};

export default GrayBox;
import React from 'react';
import { Box, VStack, Text, Link, HStack, Image, Spacer } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { FiHome, FiUsers, FiPhone, FiSettings, FiLayers } from 'react-icons/fi';

const Sidebar = () => {
  const { logout } = useAuth();

  const linkStyles = {
    display: 'block',
    padding: '10px 0',
    width: '100%',
    textAlign: 'left',
    transition: 'background-color 0.3s',
    borderRadius: 'md'
  };

  const iconStyles = {
    color: '#4A5568', // Lighter color
    strokeWidth: '1.5' // Lighter stroke width
  };
  
  return (
    <Box as="nav" p={4} width="12%" minWidth="200px" height="100vh" pt={'60px'} bg="white" position="sticky" top="0">
        <Box display="flex" justifyContent="center">
            <Image src="/icons/Primary Logo.png" alt="datadasher icon" marginBottom={10} mt={4} maxW="90%" height="auto" />
        </Box>
      <VStack spacing={1} align="stretch">
        <Link as={NavLink}
              to="/"
              sx={linkStyles}
              _hover={{ bg: 'gray.100' }}
              _activeLink={{ bg: 'blue.100' }}>
          <HStack px={4}>
            <FiHome size="20px" style={iconStyles} />
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} >Home</Text>
          </HStack>
        </Link>

        <Link as={NavLink}
              to="/clients"
              sx={linkStyles}
              _hover={{ bg: 'gray.100' }}
              _activeLink={{ bg: 'blue.100' }}>
          <HStack px={4}>
            <FiUsers size="20px" style={iconStyles} />
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} >Clients</Text>
          </HStack>
        </Link>

        <Link as={NavLink}
              to="/meetings"
              sx={linkStyles}
              _hover={{ bg: 'gray.100' }}
              _activeLink={{ bg: 'blue.100' }}>
          <HStack px={4}>
            <FiPhone size="20px" style={iconStyles} />
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} >Meetings</Text>
          </HStack>
        </Link>


        <Link as={NavLink}
              to="/settings"
              sx={linkStyles}
              _hover={{ bg: 'gray.100' }}
              _activeLink={{ bg: 'blue.100' }}>
          <HStack px={4}>
            <FiSettings size="20px" style={iconStyles} />
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} >Settings</Text>
          </HStack>
        </Link>

        <Link as={NavLink}
              to="/integrations"
              sx={linkStyles}
              _hover={{ bg: 'gray.100' }}
              _activeLink={{ bg: 'blue.100' }}>
          <HStack px={4}>
            <FiLayers size="20px" style={iconStyles} />
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} >Integrations</Text>
          </HStack>
        </Link>
        <Spacer />
        <Link
          as="button"
          onClick={logout}
          sx={linkStyles}
          _hover={{ bg: 'gray.100' }}
        >
          <HStack px={4}>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>Logout</Text>
          </HStack>
        </Link>
      </VStack>
    </Box>
  );
};

export default Sidebar;